import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "q-gutter-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_q_drawer = _resolveComponent("q-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "FHr Lpr lFf" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_header, {
        class: _normalizeClass(_ctx.$q.dark.isActive ? '' : 'bg-white text-dark'),
        bordered: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar, null, {
            default: _withCtx(() => [
              false
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    icon: "sym_r_notes",
                    onClick: $setup.toggleLeftDrawer,
                    color: "orange-3",
                    "text-color": "primary",
                    unelevated: "",
                    dense: ""
                  }))
                : _createCommentVNode("v-if", true),
              _createVNode(_component_q_toolbar_title, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_img, {
                    src: 
              _ctx.$q.dark.isActive
                ? require('@/assets/logo.svg')
                : require('@/assets/logo-footer.svg')
            ,
                    "spinner-color": "primary",
                    width: "96px",
                    "spinner-size": "82px"
                  }, null, 8 /* PROPS */, ["src"])
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode(" <q-space v-else /> "),
              _createElementVNode("div", _hoisted_1, [
                _createCommentVNode(" <q-btn\n            text-color=\"primary\"\n            color=\"orange-3\"\n            dense\n            unelevated\n            icon=\"sym_r_notifications\"\n          />\n          <q-btn\n            text-color=\"primary\"\n            color=\"orange-3\"\n            dense\n            unelevated\n            @click=\"$q.dark.toggle()\"\n            :icon=\"$q.dark.isActive ? 'sym_r_dark_mode' : 'sym_r_wb_sunny'\"\n          /> "),
                _createVNode($setup["AccountMenu"])
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class"]),
      _createVNode(_component_q_drawer, {
        modelValue: $setup.leftDrawerOpen,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.leftDrawerOpen) = $event)),
        side: "left",
        "show-if-above": "",
        mini: $setup.miniState,
        "mini-width": 72,
        bordered: "",
        class: _normalizeClass(_ctx.$q.dark.isActive ? '' : 'bg-white text-dark'),
        onMouseover: _cache[1] || (_cache[1] = ($event: any) => ($setup.miniState = false)),
        onMouseout: _cache[2] || (_cache[2] = ($event: any) => ($setup.miniState = true))
      }, {
        default: _withCtx(() => [
          _createCommentVNode(" <q-item class=\"q-mb-sm\" style=\"justify-content: center\">\n        <q-item-section avatar>\n          <img\n            :src=\"require('@/assets/logotipo.svg')\"\n            v-show=\"miniState\"\n            style=\"height: 30px\"\n          />\n          <img\n            class=\"q-ml-sm\"\n            :src=\"require('@/assets/logo.svg')\"\n            v-show=\"!miniState\"\n            style=\"height: 30px\"\n          />\n        </q-item-section>\n      </q-item> "),
          _createVNode($setup["MainMenu"], { miniState: $setup.miniState }, null, 8 /* PROPS */, ["miniState"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "mini", "class"]),
      _createVNode(_component_q_page_container, {
        class: _normalizeClass(_ctx.$q.dark.isActive ? '' : 'bg-grey-1')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class"])
    ]),
    _: 1 /* STABLE */
  }))
}