import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_q_scroll_area, { class: "fit" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_list, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menus, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.id
            }, [
              (item.children && item.children?.length > 0)
                ? (_openBlock(), _createBlock(_component_q_expansion_item, {
                    key: 0,
                    "header-class": 
              item.children.filter((el) => el.route === $setup.currentRoute).length > 0
                ? $props.miniState
                  ? 'q-mx-sm bg-primary rounded-borders '
                  : 'q-mx-md bg-primary rounded-borders'
                : $props.miniState
                ? 'q-mx-sm'
                : 'q-mx-md'
            ,
                    icon: item.icon,
                    label: item.label,
                    group: "main-menu",
                    class: "overflow-hidden rounded-borders"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card, {
                        class: "bg-transparent q-mx-md",
                        flat: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_card_section, { class: "q-pa-xs" }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                                return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                  key: child.id,
                                  clickable: "",
                                  class: _normalizeClass(["q-pl-lg q-my-xs rounded-borders q-mx-md", $setup.currentRoute === child.route ? 'text-white' : '']),
                                  to: { path: child.route }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(child.label), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "to"])), [
                                  [_directive_ripple]
                                ])
                              }), 128 /* KEYED_FRAGMENT */))
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["header-class", "icon", "label"]))
                : (_openBlock(), _createBlock(_component_q_item, {
                    key: 1,
                    clickable: "",
                    to: { path: item.route },
                    class: _normalizeClass(["rounded-borders q-my-xs", 
              $setup.currentRoute === item.route
                ? $props.miniState
                  ? 'q-mx-sm bg-grey-3'
                  : 'q-mx-md bg-grey-3'
                : $props.miniState
                ? 'q-mx-sm'
                : 'q-mx-md'
            ]),
                    color: "grey-3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, { avatar: "" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            name: item.icon,
                            color: $setup.currentRoute === item.route ? 'primary' : ''
                          }, null, 8 /* PROPS */, ["name", "color"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_q_item_section, {
                        class: _normalizeClass(
                $setup.currentRoute === item.route
                  ? 'text-weight-medium text-primary'
                  : ''
              )
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.label), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "class"]))
            ], 64 /* STABLE_FRAGMENT */))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_q_list, { class: "absolute-bottom" }, {
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createBlock(_component_q_item, {
            class: _normalizeClass(["q-my-xs rounded-borders q-pa-sm text-white", $props.miniState || 'q-mx-md']),
            clickable: "",
            to: "/about"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_section, { avatar: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, { name: "sym_r_help" })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_q_item_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Ajuda")
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"])), [
            [_directive_ripple]
          ]),
          _createVNode(_component_q_item, {
            class: _normalizeClass(["q-my-xs rounded-borders q-pa-sm", $props.miniState || 'q-mx-md']),
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.logOut())),
            clickable: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_section, { avatar: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, { name: "sym_r_logout" })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_q_item_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Sair")
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}